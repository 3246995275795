
.carousel {
  &__pagination-button:after {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: var(--v-color--primary--40);
    transform: scale(0.8);
  }
  &__pagination-button:hover:after {
    background-color: var(--v-color--primary--100);
  }
  &__pagination-button--active:after {
    background-color: var(--v-color--primary--100);
    transform: scale(1);
  }
}
