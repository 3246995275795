.o-campaign {
  display: flex;
  max-width: var(--v-page-width);
  margin: 0 auto;
  min-height: var(--v-campaign-height);
  background-color: var(--v-color--primary--100);
  justify-content: center;
  flex-wrap: wrap;
  color: var(--v-color--white);

  &.Grey {
    background-color: var(--v-color--grey-light--100);
    color: var(--v-color--black);
  }
  &.Grey-light {
    background-color: var(--v-color--grey-light--10);
    color: var(--v-color--black);
  }
  &.White {
    background-color: var(--v-color--white);
    color: var(--v-color--black);
  }

  &__content {
    width: 100%;
    display: flex;

    @media (min-width: 768px) {
      width: 50%;
    }

    &.align-center {
      justify-content: center;
    }
    &.align-left {
      order: 2;
    }
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: var(--v-spacing--xxlarge);

    &.align-center {
      align-items: center;
      text-align: center;
    }
  }

  &__image-container {
    width: 100%;
    min-height: var(--v-campaign-height);
    box-sizing: border-box;

    @media (min-width: 768px) {
      width: 50%;
    }

    &--with-padding {
      padding: var(--v-spacing--xxxlarge);
    }
  }

  &__image-wrap {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  &__image {
    max-height: 100%;
    max-width: none;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__heading {
    font-size: var(--v-type-size--xxxlarge);
    margin-bottom: var(--v-spacing--medium);
    font-weight: 300;
    &--bold {
      font-weight: 700;
    }
  }
  &__description {
    margin-bottom: var(--v-spacing--medium);
    font-size: var(--v-type-size--xlarge);
    &--bold {
      font-weight: 700;
    }
  }
}
