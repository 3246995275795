:root {
  /* Scale */
  --v-type-size--xsmall: rem(14px);
  --v-type-line-height--xsmall: 1.05;
  --v-type-size--small: rem(16px);
  --v-type-line-height--small: 1.4;
  --v-type-size--medium: rem(18px);
  --v-type-line-height--medium: 1.625;
  --v-type-size--large: rem(20px);
  --v-type-line-height--large: 1.55;
  --v-type-size--xlarge: rem(24px);
  --v-type-line-height--xlarge: 1.41;
  --v-type-size--xxlarge: rem(30px);
  --v-type-line-height--xxlarge: 1.18;
  --v-type-size--xxxlarge: rem(36px);
  --v-type-line-height--xxxlarge: 1.2;
  --v-type-size--xxxxlarge: rem(64px);
  --v-type-line-height--xxxxlarge: 1.5;

  /* Body */
  --v-type--body--color: var(--v-color--greyscale--0);
  --v-type--body--font-family: "Open Sans", sans-serif;
  --v-type--body--letter-spacing: normal;
  --v-type--body--line-height: var(--v-type-line-height--medium);
  --v-type--body--font-size: var(--v-type-size--medium);
  --v-type--body--font-weight: 400;
}
