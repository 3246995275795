.iframe {
  &__container {
    max-width: var(--v-page-width);
    width: 100%;
    margin: 0 auto;
  }
  &__iframe {
    border: none;
  }
}
