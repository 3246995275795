
$breakpoint-tablet: 1024px;
.header {
  background: var(--v-color--primary--100);
  height: 100%;

  &__container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: var(--v-page-width);
    margin: 0 auto;
    padding: 0 var(--v-spacing--large);
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

    a {
      display: flex;
    }

    @media (max-width: $breakpoint-tablet) {
      max-width: 61px;
    }
  }

  &__middle-area {
    height: 100%;
    flex: 1;
  }

  &__navigation {
    display: flex;
    height: 100%;
    color: var(--v-color--white);
  }

  &__navigation-item {
    padding: 0 var(--v-spacing--medium);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 21px;
    transition: all 0.3s;
    cursor: pointer;

    &.open {
      background-color: var(--v-color--white);
      color: var(--v-color--primary--100);

      .header__navigation-close-icon {
        @media (max-width: $breakpoint-tablet) {
          display: flex;
        }
      }
      .header__navigation-icon {
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
    }

    @media (min-width: $breakpoint-tablet) {
      padding: 0 25px;

      &--menu-toggle {
        display: none;
      }

      img,
      svg {
        margin-right: var(--v-spacing--small);
      }
    }

    @media (max-width: $breakpoint-tablet) {
      font-size: 0;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    height: calc(100% - 41px);
    background-color: transparent;
    backdrop-filter: blur(0px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    transform: scaleY(0);
    transform-origin: top;
    transition: background-color 0.5s;
    &.active {
      transform: scaleY(1);
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(30px);
      & .header__dropdown-content {
        transform: scaleY(1);
        transition: all 0.2s;
      }
    }
    @media (min-width: $breakpoint-tablet) {
      height: calc(100% - 72px);
    }
  }

  &__dropdown-content {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: var(--v-color--white);
    box-shadow: 0px 10px 10px 0px #0000001a;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.5s;
  }

  &__dropdown-overlay {
    flex: 1;
  }

  &__dropdown-content-wrap {
    width: 840px;
    max-width: 100%;
    padding: var(--v-spacing--xlarge) var(--v-spacing--medium);
  }
  &__dropdown-title {
    font-size: var(--v-type-size--large);
    line-height: var(--v-type-line-height--xxxlarge);
    margin-bottom: var(--v-spacing--medium);

    @media (min-width: $breakpoint-tablet) {
      font-size: var(--v-type-size--xxxlarge);
      line-height: var(--v-type-line-height--xxxlarge);
      margin-bottom: var(--v-spacing--xlarge);
    }
  }
  &__dropdown-close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    img {
      margin-left: var(--v-spacing--small);
    }
  }
  &__navigation-close-icon {
    display: none;
  }
  &__search-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: right;
  }
  &__search-form-button {
    margin-left: var(--v-spacing--medium);
  }
  &__country-list {
    display: flex;
    flex-wrap: wrap;
  }
  &__country-list-item {
    width: 100%;
    margin-bottom: var(--v-spacing--large);
    padding-right: var(--v-spacing--medium);
    box-sizing: border-box;

    @media (min-width: 768px) {
      width: calc(100% / 3);
    }
  }
  &__country-name {
    font-size: var(--v-type-size--medium);
    @media (min-width: 768px) {
      font-size: var(--v-type-size--xlarge);
    }
  }
  &__country-link {
    line-height: 22px;
    text-decoration: none;
    color: initial;
    display: block;
    @media (max-width: 768px) {
      font-size: var(--v-type-size--xsmall);
    }
  }
}
