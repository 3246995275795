@layer organisms {
  @import "./header";
  @import "./footer";
  @import "./navigation";
  @import "./breadcrumb";
  @import "./spot-list";
  @import "./campaign";
  @import "./media";
  @import "./hero";
  @import "./resources";
  @import "./search-results";
  @import "./product-detail";
  @import "./product-list";
  @import "./selling-points";
}
