.resources {
  background-color: var(--v-color--secondary--100);
  color: var(--v-color--white);

  &__container {
    max-width: var(--v-page-width);
    margin: 0 auto;
    padding: var(--v-spacing--large);
    box-sizing: border-box;
  }
  &__title {
    font-size: var(--v-type-size--xxxlarge);
    font-weight: 300;

    &--bold {
      font-weight: 700;
    }
  }
  &__description {
    font-size: var(--v-type-size--medium);
    margin-top: var(--v-spacing--small);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--v-spacing--xlarge);
  }
  &__item {
    width: 100%;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      width: calc(100% / 3);
    }
  }
  &__icon-wrap {
    margin-right: 15px;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--v-color--white);
    border-radius: 24px;
    flex-shrink: 0;
    align-self: flex-start;
  }
  &__link {
    color: var(--v-color--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
  &__name {
    font-size: var(--v-type-size--medium);
  }
  &__type {
    font-size: var(--v-type-size--xsmall);
    text-decoration: underline;
    margin-top: 5px;
  }
}
