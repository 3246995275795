.o-product-detail {
  max-width: var(--v-page-width);
  margin: 0 auto;
  display: flex;
  padding: var(--v-spacing--large);
  flex-wrap: wrap;
  word-break: break-word;
  box-sizing: border-box;

  @media (min-width: $breakpoint-tablet) {
    flex-wrap: nowrap;
  }

  &__left {
    max-width: var(--v-article-width);
    flex: 1;
    padding-right: var(--v-spacing--medium);
  }

  &__right {
    margin-left: auto;
  }

  &__sub-header {
    font-size: var(--v-type-size--xlarge);
    margin-bottom: var(--v-spacing--xlarge);
  }
  &__specs-title {
    font-size: var(--v-type-size--large);
    font-weight: 700;
    margin-bottom: 15px;
  }
  &__specs {
    font-size: var(--v-type-size--medium);
    margin-bottom: 15px;
  }
  &__description {
    font-size: var(--v-type-size--medium);
    line-height: var(--v-type-line-height--medium);
  }
}
