.breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  margin: 0;
  padding: var(--v-spacing--small) var(--v-spacing--medium);
  max-width: var(--v-page-width);
  margin: 0 auto;

  &__item {
    padding: var(--v-spacing--xsmall) 0 var(--v-spacing--xsmall)
      var(--v-spacing--small);
    font-size: var(--v-type-size--xsmall);
    line-height: var(--v-type-line-height--small);
    color: var(--v-color--primary--100);
  }
  &__link {
    color: var(--v-color--primary--100);
    padding-right: var(--v-spacing--small);
  }
}
