@layer base {
  body {
    font-family: var(--v-type--body--font-family);
    position: relative;
    min-height: 100vh;
  }
  h1,
  .h1 {
    font-size: var(--v-type-size--xxxxlarge);
    line-height: var(--v-type-line-height--xxxxlarge);
    margin: 0 0 var(--v-spacing--small) 0;
  }
  h2,
  .h2 {
    font-size: var(--v-type-size--xxxlarge);
    line-height: var(--v-type-line-height--xxxxlarge);
    margin: 0 0 var(--v-spacing--small) 0;
  }
  h3,
  .h3 {
    font-size: var(--v-type-size--xxxlarge);
    line-height: var(--v-type-line-height--xxxxlarge);
    margin: 0 0 var(--v-spacing--small) 0;
  }

  h4,
  .h4 {
    font-size: var(--v-type-size--xlarge);
    line-height: var(--v-type-line-height--xxxxlarge);
    margin: 0 0 var(--v-spacing--small) 0;
  }

  h5,
  .h5 {
    font-size: var(--v-type-size--large);
    line-height: var(--v-type-line-height--xxxxlarge);
    margin: 0 0 var(--v-spacing--small) 0;
  }

  .wysiwyg {
    font-size: var(--v-type-size--medium);
    line-height: var(--v-type-line-height--medium);
    padding: var(--v-spacing--large);
  }
  p,
  ul,
  ol {
    margin: 0 0 var(--v-spacing--small) 0;
  }
  table {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    display: block;
    border-collapse: collapse;
    margin: var(--v-spacing--large) 0 var(--v-spacing--small);
    font-size: var(--v-type-size--small);
    line-height: var(--v-type-line-height--small);
    td,
    th {
      padding: var(--v-spacing--medium);
      border: 1px solid var(--v-color--secondary--20);
    }
    th {
      text-align: left;
      background-color: var(--v-color--grey-light--20);
    }
  }
  img {
    max-width: 100%;
  }
}
