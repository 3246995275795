input {
  height: 46px;
  border: 1px solid #4d4d4d;
  box-sizing: border-box;
  padding: 13px;
  flex: 100%;
  margin-bottom: var(--v-type-size--medium);

  @media (min-width: 768px) {
    flex: 1;
  }
}
