.o-selling-points {
  &__container {
    max-width: var(--v-page-width);
    margin: 0 auto;
    padding: var(--v-spacing--large);
    box-sizing: border-box;
    background-color: var(--v-color--grey-light--20);

    &.Green {
      background-color: var(--v-color--primary--100);
      color: var(--v-color--white);
    }
    &.White {
      background-color: var(--v-color--white);
    }
    &.Grey-light {
      background-color: var(--v-color--grey-light--10);
    }
  }
  &__title {
    font-size: var(--v-type-size--xxxlarge);
    font-weight: 300;

    &--bold {
      font-weight: 700;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: var(--v-spacing--xlarge);
    margin-bottom: var(--v-spacing--small);
    justify-content: center;
  }
  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--v-spacing--medium);
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    color: inherit;

    @media (min-width: 768px) {
      width: calc(100% / 3);
    }
  }
  &__icon-wrap {
    height: 120px;
    width: 120px;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    margin-bottom: var(--v-spacing--medium);
  }
  &__icon {
    max-width: none;
  }
  &__item-title {
    font-size: var(--v-type-size--xlarge);
    font-weight: 700;
    margin-bottom: var(--v-spacing--xsmall);
  }
  &__item-description {
    font-size: var(--v-type-size--small);
  }
}
