.two-column {
  max-width: var(--v-page-width);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  &__left {
    width: var(--v-article-width);
    max-width: 100%;
  }
  &__right {
    flex: 1;
    background-color: var(--v-color--secondary--10);
    @media (min-width: 768px) {
      min-width: var(--v-aside-width);
    }
  }
}
