:root {
	/* Primary Green */
	--v-color--primary--100: #006656;
	--v-color--primary--80: #006656cc;
	--v-color--primary--60: #00665699;
	--v-color--primary--40: #00665666;
	--v-color--primary--20: #00665633;
	--v-color--primary--10: #0066561a;

	/* Secondary Dark blue*/
	--v-color--secondary--100: #22505f;
	--v-color--secondary--80: #22505fcc;
	--v-color--secondary--60: #22505f99;
	--v-color--secondary--40: #22505f66;
	--v-color--secondary--20: #22505f33;
	--v-color--secondary--10: #22505f1a;

	/* Tertiary Light blue*/
	--v-color--tertiary--100: #00aecb;
	--v-color--tertiary--80: #00aecbcc;
	--v-color--tertiary--60: #00aecb99;
	--v-color--tertiary--40: #00aecb66;
	--v-color--tertiary--20: #00aecb33;
	--v-color--tertiary--10: #00aecb1a;

	/* quaternary Light green */
	--v-color--quaternary--100: #76d750;
	--v-color--quaternary--80: #76d750cc;
	--v-color--quaternary--60: #76d75099;
	--v-color--quaternary--40: #76d75066;
	--v-color--quaternary--20: #76d75033;
	--v-color--quaternary--10: #76d7501a;

	/* quinary Orange */
	--v-color--quinary--100: #ff5800;
	--v-color--quinary--80: #ff5800cc;
	--v-color--quinary--60: #ff580099;
	--v-color--quinary--40: #ff580066;
	--v-color--quinary--20: #ff580033;
	--v-color--quinary--10: #ff58001a;

	/* Greyscale */
	--v-color--grey-dark--100: #565a5c;
	--v-color--grey-dark--80: #565a5ccc;
	--v-color--grey-dark--60: #565a5c99;
	--v-color--grey-dark--40: #565a5c66;
	--v-color--grey-dark--20: #565a5c33;
	--v-color--grey-dark--10: #565a5c1a;

	--v-color--grey-light--100: #cdcfcb;
	--v-color--grey-light--80: #cdcfcbcc;
	--v-color--grey-light--60: #cdcfcb99;
	--v-color--grey-light--40: #cdcfcb66;
	--v-color--grey-light--20: #cdcfcb33;
	--v-color--grey-light--10: #cdcfcb1a;

	--v-color--black: #000000;
	--v-color--white: #ffffff;

	/* Feedback */
	--v-color--feedback--info--50: #0033a0;

	--v-color--feedback--error--50: #e30613;

	--v-color--feedback--success--50: #64a70b;

	--v-color--feedback--warning--50: #fedd00;
}
