.o-product-list {
  &__container {
    max-width: calc(var(--v-page-width) + 60px);
    margin: 0 auto;
  }
  &__container-heading {
    max-width: var(--v-page-width);
    margin: 0 auto;
  }

  &__heading {
    font-size: var(--v-type-size--xxxlarge);
    padding: var(--v-spacing--large) var(--v-spacing--large)
      var(--v-spacing--small);
    font-weight: 700;
  }

  &__spots {
    display: flex;
    flex-wrap: wrap;
    padding: calc(var(--v-spacing--large) / 2);
  }
  &__spot {
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;

    @media (min-width: 768px) {
      width: calc(100% / 3);
    }

    @media (min-width: 1440px) {
      min-width: 450px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }
  &__spot-wrap {
    display: flex;
    flex: 100%;
    flex-direction: column;
    text-decoration: none;
    padding: calc(var(--v-spacing--large) / 2);
  }
  &__spot-image {
    margin-bottom: 15px;
  }
  &__spot-info {
    display: flex;
    flex-direction: column;
    padding: calc(var(--v-spacing--large) / 2);
    line-height: 22px;
    flex-grow: 1;
  }
  &__spot-header {
    font-size: var(--v-type-size--xlarge);
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 28px;
  }
  &__spot-sub-header {
    margin-bottom: var(--v-spacing--medium);
  }
  &__button {
    margin-top: auto;
    align-self: flex-start;
  }
  &__spot-specs {
    margin-bottom: var(--v-spacing--medium);
  }
}
