.a-btn {
  border: none;
  background-color: var(--v-color--primary--100);
  color: var(--v-color--white);
  padding: 0 var(--v-spacing--medium);
  line-height: 46px;
  border-radius: 2px;
  height: auto;
  font-size: var(--v-type-size--medium);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  img {
    margin-right: var(--v-spacing--small);
  }

  &--secondary {
    background-color: var(--v-color--white);
    color: var(--v-color--black);
  }
}
.a-link {
  color: var(--v-color--primary--100);
  font-size: var(--v-type-size--xlarge);
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-bottom: var(--v-spacing--small);
  &:before {
    content: "";
    box-sizing: border-box;
    height: 15px;
    width: 15px;
    margin-right: 20px;
    border-right: 2px solid var(--v-color--primary--100);
    border-top: 2px solid var(--v-color--primary--100);
    transform: rotate(45deg);
  }
  &--small {
    &:before {
      height: 10px;
      width: 10px;
    }
  }
}
