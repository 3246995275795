.o-media {
  &__container {
    max-width: var(--v-page-width);
    margin: 0 auto;
    position: relative;

    iframe {
      display: block;
      cursor: pointer;
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
  }

  &__caption {
    line-height: 24px;
    padding: 8px 10px;
  }
}
