.o-hero {
  max-width: var(--v-page-width);
  margin: 0 auto;

  &__container {
    width: 100%;
    min-height: 550px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    text-decoration: none;
    z-index: 0;

    &.Right {
      justify-content: flex-end;
    }
  }
  &__image {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    height: 0;
    width: 0;
    object-fit: cover;
  }
  &__overlay {
    position: relative;
    width: 960px;
    max-width: 100%;
    background-color: var(--v-color--primary--80);
    color: var(--v-color--white);
    display: flex;
    align-items: center;
    min-height: 275px;

    &.Blue {
      background-color: var(--v-color--secondary--80);
    }
  }
  &__text-wrap {
    padding: var(--v-spacing--large);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__heading {
    font-size: var(--v-type-size--xxxlarge);
    font-weight: 300;
    text-align: left;

    &--bold {
      font-weight: 700;
    }
  }
  &__text {
    padding-top: var(--v-spacing--small);
    display: flex;

    svg {
      padding-left: 15px;
      box-sizing: content-box;
    }
  }
  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 0;
  }
  .a-btn {
    margin-top: var(--v-spacing--medium);
  }
  ol.carousel__pagination {
    margin: var(--v-spacing--small) 0;
  }
  .carousel {
    .o-hero__overlay {
      background-color: transparent;
      transition: background-color ease 0.3s;
    }
    .o-hero__text-wrap {
      div {
        transition: all ease 0.2s;
        transform: translateY(300px);
      }
    }
    &__slide--active {
      .o-hero__overlay {
        background-color: var(--v-color--primary--80);
        &.Blue {
          background-color: var(--v-color--secondary--80);
        }
      }
      .o-hero__text-wrap {
        div {
          transform: translateY(0);
          &:nth-child(1) {
            transition-delay: 0.2s;
          }
          &:nth-child(2) {
            transition-delay: 0.4s;
          }
          &:nth-child(3) {
            transition-delay: 0.6s;
          }
          &:nth-child(4) {
            transition-delay: 0.8s;
          }
        }
      }
    }
  }
}
