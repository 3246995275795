
$yellow: yellow;

.root {
	background: red;
	color: white;
}
.example {
	color: $yellow;
}
