:root {
  --v-spacing--xsmall: rem(5px);
  --v-spacing--small: rem(10px);
  --v-spacing--medium: rem(20px);
  --v-spacing--large: rem(30px);
  --v-spacing--xlarge: rem(40px);
  --v-spacing--xxlarge: rem(60px);
  --v-spacing--xxxlarge: rem(80px);
  --v-spacing--xxxxlarge: rem(120px);

  @media (max-width: 768px) {
    --v-spacing--xsmall: rem(3px);
    --v-spacing--small: rem(5px);
    --v-spacing--medium: rem(10px);
    --v-spacing--large: rem(15px);
    --v-spacing--xlarge: rem(20px);
    --v-spacing--xxlarge: rem(30px);
    --v-spacing--xxxlarge: rem(40px);
    --v-spacing--xxxxlarge: rem(60px);
  }
}
