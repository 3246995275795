.search-results {
  max-width: var(--v-page-width);
  margin: 0 auto;
  padding: var(--v-spacing--large);

  &__container {
    max-width: var(--v-article-width);
  }

  &__form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: right;
  }
  &__form-button {
    margin-left: var(--v-spacing--medium);
  }
  &__heading {
    font-size: var(--v-type-size--xxxlarge);
    margin-bottom: 25px;
    font-weight: 300;
    &--bold {
      font-weight: 700;
    }
  }
  &__count {
    font-weight: 700;
  }
  &__pagination {
    display: flex;
    justify-content: center;
    margin: var(--v-spacing--xlarge) 0;
  }
  &__pagination-link {
    height: 29px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--v-color--primary--100);
    margin: 0 5px;

    &--rotated {
      transform: rotate(180deg);
    }
  }
  &__pagination-count {
    margin: 0 var(--v-spacing--large);
    display: flex;
    align-items: center;
  }
  &__list {
    margin-top: var(--v-spacing--xlarge);
  }
  &__result {
    margin-bottom: var(--v-spacing--large);
  }
  &__result-title {
    font-size: var(--v-type-size--large);
    font-weight: 700;
    margin-bottom: var(--v-spacing--small);
  }
  &__result-text {
    font-size: var(--v-type-size--medium);
    margin-bottom: 15px;
  }
  &__result-link {
    font-size: var(--v-type-size--small);
  }
}
