.o-spot-list {
  &__container {
    max-width: calc(var(--v-page-width) + 60px);
    margin: 0 auto;
  }

  &__heading {
    font-size: var(--v-type-size--xxxlarge);
    padding: var(--v-spacing--large) var(--v-spacing--large) 0;
  }

  &__heading-top {
    font-weight: 300;
  }
  &__heading-bottom {
    font-weight: 700;
  }

  &__spots {
    display: flex;
    flex-wrap: wrap;
    padding: 0 calc(var(--v-spacing--large) / 2)
      calc(var(--v-spacing--large) / 2) calc(var(--v-spacing--large) / 2);
  }
  &__spot {
    display: flex;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
      width: calc(100% / 3);
    }

    @media (min-width: 1440px) {
      min-width: 450px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1.33;
    }

    &--two-column {
      img {
        aspect-ratio: 2.05;
      }
      @media (min-width: 768px) {
        width: 50%;
      }
    }
  }

  &__spot-headline {
    font-size: var(--v-type-size--xlarge);
  }
  &__spot-sub-headline {
    line-height: 22px;
  }
  &__spot-wrap {
    display: flex;
    flex: 100%;
    flex-direction: column;
    text-decoration: none;
    padding: calc(var(--v-spacing--large) / 2);
  }
  &__spot-bottom {
    background-color: var(--v-color--primary--100);
    color: var(--v-color--white);
    padding: var(--v-spacing--large);
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;

    svg {
      min-width: 22px;
      align-self: center;
    }
  }
  &__spot-bottom-text {
    padding-right: var(--v-spacing--medium);
  }
}
