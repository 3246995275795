.primary-navigation {
  width: 100%;
  position: absolute;
  height: calc(100% - 41px);
  flex-direction: column;

  &.open {
    display: flex;
  }

  @media (max-width: $breakpoint-tablet) {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(30px);
    display: none;
    z-index: 1;
  }

  @media (min-width: $breakpoint-tablet) {
    height: calc(100% - 72px);
    position: relative;
  }

  &.has-subnav {
    margin-bottom: rem(63px);
  }

  &__overlay {
    flex: 1;
  }

  &__first-level {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--v-color--white);
    box-shadow: 0px 10px 10px 0px #0000001a;
    padding: var(--v-spacing--medium) 0;

    @media (min-width: $breakpoint-tablet) {
      background-color: var(--v-color--secondary--20);
      border-bottom: 1px solid var(--v-color--primary--20);
      box-shadow: none;
      padding: 0;
    }
  }
  &__first-level-link {
    line-height: rem(46px);
    text-decoration: none;
    color: var(--v-color--black);
    padding: 0 var(--v-spacing--medium);
    @media (min-width: $breakpoint-tablet) {
      line-height: rem(58px);
    }
    &.is-active {
      font-weight: 700;
      @media (min-width: $breakpoint-tablet) {
        border-bottom: 1px solid var(--v-color--primary--80);
      }

      & ~ .primary-navigation__second-level {
        @media (min-width: $breakpoint-tablet) {
          display: flex;
        }
      }
    }
  }
  &__second-level {
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--v-color--white);
    padding: 0 var(--v-spacing--medium);

    @media (min-width: $breakpoint-tablet) {
      position: absolute;
      background-color: var(--v-color--secondary--10);
      border-bottom: 1px solid var(--v-color--primary--20);
      padding: 0;
    }
  }
  &__second-level-link {
    line-height: rem(62px);
    text-decoration: none;
    color: var(--v-color--black);
    padding: 0 var(--v-spacing--medium);

    &.is-active {
      font-weight: 700;
      @media (min-width: $breakpoint-tablet) {
        border-bottom: 1px solid var(--v-color--primary--80);
      }
    }
    @media (max-width: $breakpoint-tablet) {
      line-height: rem(38px);
      font-size: var(--v-type-size--small);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: var(--v-type-size--large);
    flex-wrap: wrap;
    justify-content: space-between;

    &--no-children {
      justify-content: flex-start;

      .primary-navigation__subnav-toggle-label {
        padding: 0 var(--v-spacing--small);
        transform: rotate(-90deg);
      }
    }

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }
  &__subnav-toggle {
    display: none;
  }
  &__subnav-toggle-label {
    padding: 0 var(--v-spacing--large);
    display: flex;
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
  &__subnav-toggle:checked ~ .primary-navigation__second-level {
    @media (max-width: $breakpoint-tablet) {
      display: flex;
    }
  }
  &__subnav-toggle:checked ~ .primary-navigation__subnav-toggle-label {
    transform: rotate(180deg);
  }
  &__item--no-children {
    .primary-navigation__subnav-toggle:checked
      ~ .primary-navigation__subnav-toggle-label {
      transform: rotate(-90deg);
    }
  }
}
