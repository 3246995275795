.o-footer {
  &__container {
    max-width: var(--v-page-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    flex-wrap: wrap;
  }
  &__top {
    padding: var(--v-spacing--large);
    background-color: var(--v-color--grey-light--100);
  }
  &__bottom {
    padding: var(--v-spacing--large);
    background-color: var(--v-color--grey-dark--100);
    color: var(--v-color--white);
  }
  &__links {
    display: flex;
  }
  &__link {
    color: var(--v-color--white);
    text-decoration: none;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      width: 1px;
      height: 15px;
      display: inline-block;
      background-color: var(--v-color--white);
      margin: 0 var(--v-spacing--small);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  &__social-links {
    display: flex;
  }
  &__social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--v-color--grey-dark--100);
    width: 50px;
    height: 50px;
    margin-right: 25px;
    border-radius: 2px;
  }
}
